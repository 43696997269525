import { Check, Close, PersonOutline, PlaceOutlined } from '@mui/icons-material'
import CircleIcon from '@mui/icons-material/Circle'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined'
import {
  Button,
  Divider,
  Tab,
  Tabs as TabsMUI,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { useDeleteEvent } from 'api/hooks/delete'
import { useGetRegistry } from 'api/hooks/get'
import { usePatchRsvp } from 'api/hooks/patch'
import { usePostImage, usePostRsvp } from 'api/hooks/post'
import COLORS from 'assets/colors'
import About from 'components/Event/About'
import EventContent from 'components/Event/EventContent'
import GuestListModal from 'components/Event/GuestList/GuestListModal'
import Polls from 'components/Event/Polls/index'
import Supplies from 'components/Event/Supplies'
import Tasks from 'components/Event/Tasks'
import { AvatarGroup, AvatarUser } from 'components/common/Avatar'
import EllipsisMenu from 'components/common/EllipsisMenu'
import Flex from 'components/common/Flex'
import Link from 'components/common/Link'
import { useEvent } from 'contexts/EventContext'
import { useModal } from 'contexts/ModalContext'
import { useSnackbar } from 'contexts/SnackbarContext'
import { useAuth } from 'hooks/useAuth'
import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { displayDateTime, getEventIdfromLink } from 'utils'

export const rsvpMap = {
  YES: {
    copy: 'Going',
    icon: <Check sx={{ color: 'success.main' }} fontSize="16px" />,
  },
  MAYBE: {
    copy: 'Maybe',
    icon: (
      <Typography color="warning.main" width="16px" textAlign="center" fontWeight={600}>
        ?
      </Typography>
    ),
  },
  NO: {
    copy: "Can't go",
    icon: <Close sx={{ color: 'error.main' }} fontSize="16px" />,
  },
}

const Event = () => {
  const { user } = useAuth()
  if (!user) getEventIdfromLink(window.location.href)
  const theme = useTheme()
  const { showModal } = useModal()
  const navigate = useNavigate()
  const location = useLocation()
  const hash = location.hash.substring(1).toLowerCase()
  const {
    fetchEvent,
    fetchAttendeeIncompletedTasks,
    fetchAttendeeCompletedTasks,
    fetchPolls,
    isHost,
    fetchRsvps,
    fetchImage,
  } = useEvent()
  const { mutateAsync: deleteEvent } = useDeleteEvent()
  const { mutate: patchRsvp, isPending: isPendingPatch } = usePatchRsvp()
  const fetchRegistry = useGetRegistry()
  const { mutate: postRsvp, isPending: isPendingPost } = usePostRsvp()
  const { openSnackbar } = useSnackbar()
  const { mutate: postImage } = usePostImage()

  const [tempRsvpStatus, setTempRsvpStatus] = useState()
  const event = fetchEvent.data
  const rsvp = fetchRsvps.data?.find(({ user_id }) => user_id === user?.id)
  const isRsvp = rsvp?.attending === 'YES'
  const isPending = isPendingPost || isPendingPatch
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const imageUrl = fetchImage.data.url

  const handleRsvp = (status) => {
    setTempRsvpStatus(status)
    !rsvp
      ? postRsvp({ attending: status, party_size: 1 })
      : patchRsvp({
          attending: status,
          party_size: 1,
          user_to_update: isHost ? user.id : undefined,
        })
  }

  const handleCopyLink = () => {
    const url =
      `https://${
        process.env.NODE_ENV === 'development' ? 'localhost:3000' : 'app.groupup.social'
      }/events/` + event.id
    navigator.clipboard.writeText(url.toString())
    openSnackbar('Copied to clipboard!')
  }

  const handleUploadPhoto = async () => {
    document.getElementById('fileInput').click()
  }

  const handleFileChange = async (e) => {
    const file = e.target.files[0]
    if (file && (file.type === 'image/png' || file.type === 'image/jpeg')) {
      await postImage({
        type: 'EVENT',
        linked_id: event.id,
        file,
        fileType: file.type,
      })
    }
  }

  const Buttons = user ? (
    <Flex row gap="8px" alignSelf="center">
      {['YES', 'MAYBE', 'NO'].map((rsvpStatus) => {
        return (
          <Button
            variant="text"
            key={rsvpStatus}
            onClick={() => handleRsvp(rsvpStatus)}
            sx={(theme) => {
              return {
                ...style.button,
                ...((tempRsvpStatus ?? rsvp?.attending) === rsvpStatus && {
                  border: `1px solid ${theme.palette.divider}`,
                }),
              }
            }}
            disabled={isPending}
            startIcon={rsvpMap[rsvpStatus].icon}
          >
            {rsvpMap[rsvpStatus].copy}
          </Button>
        )
      })}
    </Flex>
  ) : (
    <Flex row gap="8px" alignSelf="center">
      <Button
        onClick={() => navigate('/login')}
        sx={{ alignSelf: 'center', maxWidth: '250px', width: '100%' }}
      >
        Log in to RSVP
      </Button>
    </Flex>
  )

  const tabs = [
    {
      label: 'About',
      Component: <About />,
    },
    {
      label: 'Supplies',
      hasAlert:
        isRsvp &&
        fetchRegistry.data.entries &&
        Object.entries(fetchRegistry.data.entries[0]).some(([_, items]) =>
          Object.entries(items).some(([_, { alert, claims }]) => alert && !claims[user.id])
        ),
      Component: <Supplies />,
      hide: !isRsvp && !isHost,
    },
    {
      label: 'Tasks',
      hasAlert: !!fetchAttendeeIncompletedTasks.data.length,
      Component: <Tasks />,
      hide:
        !isHost ||
        (!isRsvp &&
          fetchAttendeeIncompletedTasks.data.length + fetchAttendeeCompletedTasks.data.length ===
            0),
    },
    {
      label: 'Polls',
      Component: <Polls />,
      hasAlert: isRsvp && !!fetchPolls.data.some(({ hasVoted }) => !hasVoted),
      hide: !isRsvp && !isHost,
    },
  ]

  const filteredTabs = tabs.filter(({ hide }) => !hide)
  const tabIdx = Math.max(
    filteredTabs.findIndex((tab) => tab.label.toLowerCase() === hash),
    0
  )

  return (
    <Flex>
      <Flex backgroundColor="white" borderBottom="1px solid" borderColor="divider">
        <Flex
          sx={{
            width: '100%',
            maxWidth: '1000px',
            alignSelf: 'center',
            padding: ['16px 16px 0 16px', '16px 32px 0 32px'],
            position: 'relative',
          }}
        >
          {isHost && (
            <EllipsisMenu
              sx={{
                position: 'absolute',
                right: '24px',
                top: ['20px', '24px'],
              }}
              items={[
                {
                  name: 'Edit event',
                  onClick: () => navigate(`/events/${event.id}/edit`),
                },
                {
                  name: 'Delete event',
                  confirm: true,
                  onClick: async () => await deleteEvent(),
                },
              ]}
            />
          )}
          <Flex
            flexDirection={['column', 'row', 'row']}
            justifyContent="space-between"
            width="100%"
            gap="12px"
          >
            <Flex gap="12px" justifyContent="center" justifyItems="center">
              <Flex gap="8px">
                <Typography variant="h3" pr="6px">
                  {event.title}
                </Typography>
                <Flex row flexWrap="wrap">
                  <Typography variant="h6" pr="6px" color={COLORS.black70}>
                    {displayDateTime(event.start_date_time)}
                    {event.end_date_time && ` — `}
                  </Typography>
                  <Typography variant="h6" noWrap color={COLORS.black70}>
                    {event.end_date_time && `${displayDateTime(event.end_date_time)}`}
                  </Typography>
                </Flex>
              </Flex>

              <Flex gap="8px">
                <Flex row sx={style.detailRow}>
                  <PersonOutline sx={{ fontSize: '20px' }} />
                  <Typography>Hosted by {event.host}</Typography>
                </Flex>
                {user && (
                  <Flex row sx={style.detailRow}>
                    <PlaceOutlined sx={{ fontSize: '20px' }} />
                    <Link
                      url={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                        event.location
                      )}`}
                      text={event.location}
                    />
                  </Flex>
                )}
                <Flex row sx={style.detailRow}>
                  <ContentCopyIcon sx={{ fontSize: '20px' }} />
                  <Link onClick={handleCopyLink} text="Copy event link" />
                </Flex>
                <Flex row gap="8px" alignItems="flex-start" sx={style.detailRow}>
                  <GroupAddOutlinedIcon sx={{ mt: '2px', fontSize: '20px' }} />
                  <Flex
                    gap="4px"
                    onClick={() => user && showModal(<GuestListModal />)}
                    sx={{ cursor: 'pointer' }}
                  >
                    <Typography variant="body2" sx={{ '&:hover': { color: 'text.primary' } }}>
                      {event.rsvp_yes_count}{' '}
                      {event.rsvp_yes_count === 1 ? 'person is going' : 'people are going'}
                    </Typography>
                    <AvatarGroup small max={9} sx={{ alignSelf: 'flex-start' }}>
                      {fetchRsvps.data
                        .filter(({ attending }) => attending === 'YES')
                        .map(({ user_id }) => (
                          <AvatarUser key={user_id} userId={user_id} showTooltip />
                        ))}
                    </AvatarGroup>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>

            <Flex
              id="image"
              alignItems="center"
              justifyContent="center" // Centers the content horizontally
              flexDirection="column" // Stack content vertically
              width={['100%', '50%', '25%']} // Full width on mobile, 50% on larger screens
              minHeight="50px" // Ensures the Flex takes up full height of the container
            >
              {imageUrl ? (
                <Flex
                  width={['100%', '65%']}
                  component="img"
                  sx={{
                    borderRadius: '8px',
                    boxShadow: 3,
                    marginLeft: '12px',
                    marginRight: '12px',
                  }}
                  src={imageUrl}
                  loading="lazy"
                />
              ) : null}

              {isHost && (
                <Button
                  onClick={handleUploadPhoto}
                  sx={{
                    mt: imageUrl ? '12px' : '0', // Align at bottom if no image is present
                    alignSelf: 'center', // Center the button horizontally
                  }}
                >
                  {imageUrl ? 'Update' : 'Upload'} Photo
                  <input
                    type="file"
                    id="fileInput"
                    accept="image/png, image/jpeg"
                    hidden
                    onChange={(e) => handleFileChange(e)}
                  />
                </Button>
              )}
            </Flex>
            {isMobile && Buttons}
          </Flex>

          <Divider sx={{ mt: '16px' }} />
          <Flex row>
            <Flex row justifyContent="flex-start" width={["100%", "50%"]}>
              <TabsMUI value={tabIdx}>
                {filteredTabs.map((tab, idx) => (
                  <Tab
                    key={tab.label}
                    label={
                      <Flex row gap="8px">
                        {tab.label}
                        {isRsvp && tab.hasAlert && (
                          <CircleIcon sx={{ fontSize: '6px', color: 'error.main' }} />
                        )}
                      </Flex>
                    }
                    value={idx}
                    onClick={() => navigate(`#${tab.label.toLowerCase()}`)}
                    sx={{ pt: '16px', pb: '16px' }}
                  />
                ))}
              </TabsMUI>
            </Flex>
            <Flex row justifyContent={user ? 'flex-end' : 'center'} sx={{ flex: 1 }}>
              {!isMobile && Buttons}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <EventContent ContentComponent={filteredTabs[tabIdx].Component} />
    </Flex>
  )
}

export default Event

const style = {
  detailRow: {
    gap: '8px',
    color: 'text.secondary',
  },
  button: {
    padding: '6px 12px 6px 12px',
    gap: '4px',
    color: 'text.primary',
    border: `1px solid white`,
    '&:hover': { backgroundColor: 'divider', color: 'text.primary' },
    fontSize: '16px',
    fontWeight: '400',
  },
}
