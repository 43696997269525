import {
  AvatarGroup as AvatarGroupMUI,
  Avatar as AvatarMUI,
  Tooltip,
  Typography,
} from '@mui/material'
import COLORS from 'assets/colors'
import { useEvent } from 'contexts/EventContext'
import Flex from './Flex'

const ConditionalFlex = ({ showName, children }) =>
  showName ? (
    <Flex row gap="8px">
      {children}
    </Flex>
  ) : (
    children
  )

export const AvatarUser = ({ userId, showName, color, subtitle, showTooltip }) => {
  const { fetchUsers } = useEvent()
  const fullname = fetchUsers.data[userId]?.first_name + ' ' + fetchUsers.data[userId]?.last_name
  const initials =
    fullname
      ?.split(' ')
      .map((name) => name[0])
      .join('') ?? '?'

  return (
    <ConditionalFlex showName={showName}>
      <Tooltip
        title={!showName && showTooltip && fullname}
        arrow
        placement="top"
        slotProps={{
          popper: {
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, -6],
                },
              },
            ],
          },
        }}
      >
        <AvatarMUI>{initials?.toUpperCase()}</AvatarMUI>
      </Tooltip>
      {showName && (
        <Flex>
          <Typography color={color}>{fullname}</Typography>
          {subtitle && (
            <Typography variant="subtitle1" color={COLORS.black50}>
              {subtitle}
            </Typography>
          )}
        </Flex>
      )}
    </ConditionalFlex>
  )
}

export const AvatarGroup = ({ children, small, sx, ...props }) => {
  const sizeProps = small
    ? {
        cursor: 'pointer',
        '& .MuiAvatar-root': {
          width: '28px',
          height: '28px',
          fontSize: '14px',
          fontWeight: 'bold',
        },
      }
    : {}

  return (
    <AvatarGroupMUI sx={{ ...sizeProps, ...sx }} {...props}>
      {children}
    </AvatarGroupMUI>
  )
}
